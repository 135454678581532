import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavRoad from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  Button,
  Input,
  Span,
  Textarea,
  MessageParagraph,
} from "../../../StyleComponents/styles"

const seeAlsoArray = [
  "/base64-encoder",
  "/base32-decoder",
  "/base32-encoder",
  "/binary-encoder",
]

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  { name: "Base64 Decoder", link: "/base64-decoder/" },
]

function Base64Decoder(props) {
  let [b64Textarea, setb64Textarea] = useState("")
  let [errorFormat, seterrorFormat] = useState("")
  let [fnameExtension, setfnameExtension] = useState("")

  function b64TextareaC(e) {
    setb64Textarea(e.target.value)
  }
  function fnameExtensionC(e) {
    setfnameExtension(e.target.value)
  }

  function handleFileSelect(evt) {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var f = evt.target.files[0]
      var reader = new FileReader()
      reader.onload = (function () {
        return function (e) {
          var binaryData = e.target.result
          setb64Textarea(binaryData)
        }
      })(f)
      reader.readAsBinaryString(f)
    }
  }

  function toFile() {
    if (b64Textarea === "") {
      seterrorFormat("Upload file or Input Base64 To Decode To a File")
    } else {
      var b64 = b64Textarea
      var bin
      try {
        bin = atob(b64)
      } catch (exception) {
        seterrorFormat("Not Base64 format!!")
        return
      }
      var buf = new ArrayBuffer(bin.length)
      var data = new Uint8Array(buf)
      var fname = fnameExtension
      if (fname === "") {
        fname = "result.data"
      }
      for (var i = 0; i < bin.length; i++) {
        data[i] = bin[i].charCodeAt()
      }
      var b = new Blob([data], { type: "application/octet-stream" })
      let tag
      tag = document.createElement("a")
      tag.href = window.URL.createObjectURL(b)
      tag.download = fname
      document.body.appendChild(tag)
      tag.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      )
      tag.remove()
      window.URL.revokeObjectURL(tag.href)
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Base64 Decoder Files, Base64 to File"
        description="Base64 Decoder Files, Base64 to File, Simple Decode files from Base64 format, upload a file or Paste base64 in the text area and click the button step by step."
        keywords={[
          "base64 to file, decode file,decode files, decode audio, decode video, decode image, decode zip file, base64 decode, file base64 decode, file decode encode, decode mp3,decode png, decode mp4, base64 mp3 decode, base64 wmv decode, zip file decode , gif decode, base64 aac, decode 3gp base 64, flv decode base 64, videos base64 decode and download, decode files base64.",
        ]}
      />
      <NavRoad listPages={navRoadArray} />
      <div className="container">
        <Title>Base64 Decoder</Title>
        <SubTitle>Transfer Base64 To Files</SubTitle>
        <div className="mb-2">
          <input
            type="file"
            id="files"
            name="files"
            onChange={handleFileSelect}
          />
        </div>
        <div>
          <Span>Input Base64 To Decode To a File</Span>
          <Textarea value={b64Textarea} onChange={b64TextareaC} />
        </div>
        <br />
        <p>
          Write Filename And Extension ({" "}
          <strong>please make sure to input the right extension</strong>)
        </p>
        <Input
          width="150px"
          type="text"
          value={fnameExtension}
          onChange={fnameExtensionC}
          placeholder="result.data"
        />
        <br />
        <div className="mt-2">
          <Button
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            id="submit"
            onClick={toFile}
          >
            Make!
          </Button>
          <span className="file-label">Decode And Download File…</span>
        </div>

        <MessageParagraph color="red">{errorFormat}</MessageParagraph>
        <br />
        <h4>Decode Base64</h4>
        <p>
          Simple decode Base64 by upload file or enter the Base64 code in the
          text area and click the button Decode, make sure to input the right
          extension for image, audio, video, zip...
          <br />
          this is the most common file extension list, make sure to input the
          right extension.
        </p>
        <strong>For Images files</strong>
        <ul>
          <li>.bmp</li>
          <li>.gif</li>
          <li>.jpg</li>
          <li>.png</li>
        </ul>

        <strong>Music and sound files</strong>
        <ul>
          <li>.mp3</li>
          <li>.wav</li>
        </ul>

        <strong>Operating system files</strong>
        <ul>
          <li>.dll</li>
          <li>.exe</li>
        </ul>

        <strong>Text and word processing documents</strong>
        <ul>
          <li>.doc</li>
          <li>.docx</li>
          <li>.rtf</li>
          <li>.txt</li>
        </ul>

        <strong>Spreadsheet files</strong>
        <ul>
          <li>.xls</li>
          <li>.xlsx</li>
          <li>.xlr</li>
          <li>.csv</li>
        </ul>

        <strong>Web Page files</strong>
        <ul>
          <li>.htm</li>
          <li>.html</li>
        </ul>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default Base64Decoder
